import apiManager from "@/api/ApiManager";
import {
    SEND_CHAT_AI_SERVER
} from "../actionsType.js";

// initial state
const state = {
};

// getters
const getters = {};

const actions = {
  [SEND_CHAT_AI_SERVER](context, requestModal) {
    return new Promise((success, reject) => {
      apiManager.post("/api/ChatAI/SendAI", requestModal)
        .then((response) => {
          success(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
export default {
  state,
  getters,
  actions,
  // mutations,
};
