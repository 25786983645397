import apiManager from "@/api/ApiManager";
import {
  GET_ACTIVE_PLANS,
  GET_CURRENT_SUBSCRIPTION,
  GET_LAST_SUBSCRIPTION,
  SEND_TOKEN_TO_SERVER,
  CREATE_STRIPE_CUSTOMER,
  GET_SUBSCRIPTION_LIST,
  DEACTIVATE_SUBSCRIPTION,
  INITIATE_GOCARDLESS_PAYMENT,
  GOCARDLESS_SEND_SUCCESS_REQUEST,
  HOLD_SUBSCRIPTION,
  List_STRIPE_CUSTOMER,
  DELETE_STRIPE_CUSTOMER,
} from "../actionsType.js";

// initial state
const state = {
};

// getters
const getters = {};

const actions = {
  [GET_ACTIVE_PLANS](context, request) {
    // alert('11')
    return new Promise((success, reject) => {
      apiManager.get("/api/Subscription/GetAllActivePlans", request)
        .then((response) => {
          success(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [GET_CURRENT_SUBSCRIPTION]() {
    return new Promise((success, reject) => {
      apiManager.get("/api/Subscription/getCurrentSubscription")
        .then((response) => {
          success(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [GET_LAST_SUBSCRIPTION]() {
    return new Promise((success, reject) => {
      apiManager.get("/api/Subscription/getLastSubscription")
        .then((response) => {
          success(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [CREATE_STRIPE_CUSTOMER]() {
    return new Promise((success, reject) => {
      apiManager.get("/api/Stripe/createStripeCustomer")
        .then((response) => {
          success(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [List_STRIPE_CUSTOMER]() {
    return new Promise((success, reject) => {
      apiManager.get("/api/Stripe/listStripeCustomer")
        .then((response) => {
          success(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [DELETE_STRIPE_CUSTOMER](context, cardIds) {
      return new Promise((success, reject) => {
        apiManager.post("/api/Stripe/delete-card" ,cardIds)
          .then((response) => {
            success(response)
          })
          .catch((error) => {
            reject(error);
          });
      });
  },
  [SEND_TOKEN_TO_SERVER](context, requestModal) {
    return new Promise((success, reject) => {
      apiManager.post("/api/Stripe/sendTokenToServer", requestModal)
        .then((response) => {
          success(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [INITIATE_GOCARDLESS_PAYMENT](context, requestModal) {
    return new Promise((success, reject) => {
      apiManager.post("/api/GoCardless/InitiateGoCardlessPayment", requestModal)
        .then((response) => {
          success(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [GOCARDLESS_SEND_SUCCESS_REQUEST](context, requestModal) {
    return new Promise((success, reject) => {
      apiManager.post("/api/GoCardless/SendSuccessRequest", requestModal)
        .then((response) => {
          success(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [GET_SUBSCRIPTION_LIST]() {
    return new Promise((success, reject) => {
      apiManager.get("/api/Subscription/getSubscriptionList")
        .then((response) => {
          success(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [DEACTIVATE_SUBSCRIPTION]() {
    return new Promise((success, reject) => {
      apiManager.get("/api/Subscription/deactivateSubscription")
        .then((response) => {
          success(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [HOLD_SUBSCRIPTION]() {
    return new Promise((success, reject) => {
      apiManager.get("/api/Subscription/holdSubscription")
        .then((response) => {
          success(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
export default {
  state,
  getters,
  actions,
  // mutations,
};
